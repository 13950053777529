<template>
  <en-dialog :model-value="modelValue" title="查找配方" center width="90%" @close="$emit('update:model-value', false)">
    <form-query :model="form.data" :init="form.init" :method="table.get">
      <en-form-item label="颜色代码">
        <en-input v-model="form.data.colorCode"></en-input>
      </en-form-item>
      <en-form-item label="颜色名称">
        <en-input v-model="form.data.colorName"></en-input>
      </en-form-item>
      <en-form-item label="车型">
        <en-input v-model="form.data.vehicleSpec"></en-input>
      </en-form-item>
      <en-form-item label="汽车品牌">
        <en-input v-model="form.data.brand"></en-input>
      </en-form-item>
      <en-form-item label="油漆品牌">
        <select-maintain
          v-model="form.data.paintTypeCode"
          :ajax="{ action: 'GET /enospray/common/lookup/:lookupType', params: (params) => (params.paths = ['PAINTTYPE']) }"
          :props="{ label: 'message', value: 'code' }"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="主色系">
        <select-maintain
          v-model="form.data.firstColorFamily"
          :ajax="{ action: 'GET /enospray/common/lookup/:lookupType', params: (params) => (params.paths = ['COLFML']) }"
          :props="{ label: 'message', value: 'code' }"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="颜色效果">
        <en-select v-model="form.data.colorEffect" class="w-full">
          <en-option label="金属漆" value="M"></en-option>
          <en-option label="纯色漆" value="P"></en-option>
        </en-select>
      </en-form-item>
      <en-form-item label="调色网点">
        <select-maintain
          v-model="form.data.tenantId"
          :ajax="{ action: 'GET /enospray/common/tenant', params: (params, value) => (params.payload = { shortName: value }) }"
          :props="{ label: 'shortName', value: 'id' }"
          remote
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="调色大师">
        <en-input v-model="form.data.preparedBy"></en-input>
      </en-form-item>
      <en-form-item label="年份">
        <en-date-picker v-model="form.data.startYear" placeholder="选择年份" type="year" class="w-full"></en-date-picker>
      </en-form-item>
      <en-form-item label="调色日期">
        <en-date-picker v-model:start="form.data.startDate" v-model:end="form.data.endDate" class="w-full"> </en-date-picker>
      </en-form-item>
      <en-form-item label="配方ID">
        <en-input v-model="form.data.idsIn"></en-input>
      </en-form-item>
    </form-query>

    <en-scrollbar :height="600" view-class="px-10">
      <span class="text-blue font-bold">原厂配方</span>
      <formula-table :data="origin.data" pagination :paging="origin.paging" :loading="origin.loading" :method="origin.get">
        <template #prepend>
          <en-table-column label="操作" width="200">
            <template #default="{ row }: { row: EnospraySprayDefinitions['SprayFormulaDto'] }">
              <en-button type="primary" link @click="table.invoke.click(row)">调用</en-button>
              <en-button type="primary" link @click="table.check.click(row)">查看</en-button>
              <en-button
                :type="table.selection.map((item) => item.id).includes(row.id) ? 'danger' : 'primary'"
                link
                @click="table.compare.click(row)"
              >
                {{ table.selection.map((item) => item.id).includes(row.id) ? '取消对比' : '加入对比' }}
              </en-button>
            </template>
          </en-table-column>
        </template>
      </formula-table>

      <span class="text-blue font-bold">高频配方</span>
      <formula-table :data="authorized.data" pagination :paging="authorized.paging" :loading="authorized.loading" :method="authorized.get">
        <template #prepend>
          <en-table-column label="操作" width="200">
            <template #default="{ row }: { row: EnospraySprayDefinitions['SprayFormulaDto'] }">
              <en-button type="primary" link @click="table.invoke.click(row)">调用</en-button>
              <en-button type="primary" link @click="table.check.click(row)">查看</en-button>
              <en-button
                :type="table.selection.map((item) => item.id).includes(row.id) ? 'danger' : 'primary'"
                link
                @click="table.compare.click(row)"
              >
                {{ table.selection.map((item) => item.id).includes(row.id) ? '取消对比' : '加入对比' }}
              </en-button>
            </template>
          </en-table-column>
        </template>
      </formula-table>

      <span class="text-blue font-bold">验证配方</span>
      <formula-table :data="unauthorized.data" pagination :paging="unauthorized.paging" :loading="unauthorized.loading" :method="unauthorized.get">
        <template #prepend>
          <en-table-column label="操作" width="200">
            <template #default="{ row }: { row: EnospraySprayDefinitions['SprayFormulaDto'] }">
              <en-button type="primary" link @click="table.invoke.click(row)">调用</en-button>
              <en-button type="primary" link @click="table.check.click(row)">查看</en-button>
              <en-button
                :type="table.selection.map((item) => item.id).includes(row.id) ? 'danger' : 'primary'"
                link
                @click="table.compare.click(row)"
              >
                {{ table.selection.map((item) => item.id).includes(row.id) ? '取消对比' : '加入对比' }}
              </en-button>
            </template>
          </en-table-column>
        </template>
      </formula-table>
    </en-scrollbar>
  </en-dialog>
</template>

<script lang="ts">
import { findIndex, omit } from 'lodash-es'
import { EnMessage } from '@enocloud/components'
import FormulaTable from '@spray/components/formula-table.vue'

const formDataInit = () => {
  return {
    colorCode: '',
    colorName: '',
    vehicleSpec: '',
    brand: '',
    paintTypeCode: '',
    firstColorFamily: '',
    colorEffect: '',
    tenantId: '',
    preparedBy: '',
    startYear: '',
    endYear: '',
    startDate: '',
    endDate: '',
    idsIn: '',
    rsexp:
      'id,imgUrls,colorCode,referencedCount,brand,vehicleSpec,startYear,preparedDateTime,colorName,paintType,diffCode,procedureType,colorFamilies,colorEffect,grayLevel,craftType,tenant[shortName],preparedBy,mark,matchRating,rgb,comment,status,colors[id,colorLayer,goods[id,workshopGoodsSpecification[id,goods[id,serialNo,name]]],weight],pearls[id,colorLayer,goods[id,workshopGoodsSpecification[goods[serialNo,name]]],weight]'
  }
}

export default factory({
  components: { FormulaTable },

  props: {
    modelValue: Boolean,
    filters: Object
  },

  watch: {
    modelValue: {
      handler() {
        if (this.modelValue) {
          this.form.init()
          Object.assign(this.form.data, this.filters)
          this.origin.get()
          this.authorized.get()
          this.unauthorized.get()
        }
      }
    }
  },

  config: {
    children: {
      form: {
        data: formDataInit(),
        init() {
          this.form.data = formDataInit()
        }
      },
      table: {
        get() {
          this.origin.get()
          this.unauthorized.get()
          this.authorized.get()
        },
        selection: [] as EnospraySprayDefinitions['SprayFormulaDto'][],
        children: {
          invoke: {
            click(row: EnospraySprayDefinitions['SprayFormulaDto']) {}
          },
          check: {
            click(row: EnospraySprayDefinitions['SprayFormulaDto']) {}
          },
          compare: {
            click(row: EnospraySprayDefinitions['SprayFormulaDto']) {
              const { selection } = this.table
              const index = findIndex(selection, ['id', row.id])
              if (index > -1) return selection.splice(index, 1)
              if (selection.length >= 4) return EnMessage.warning(`最多只能选择${selection.length}个配方进行对比`)
              if (selection.length && row.procedureType?.code !== selection[0].procedureType?.code) {
                return EnMessage.warning('请选择同类型的工序进行对比')
              }
              this.table.selection.push(row)
            }
          }
        }
      },
      origin: {
        ajax: {
          get: {
            action: 'GET /enospray/formula',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = Object.assign({ typeCode: 'O' }, omit(this.form.data, 'idsIn', 'startYear', 'endYear'))
              params.payload.idsIn = this.form.data.idsIn ? [Number(this.form.data.idsIn)] : []
              if (this.form.data.startYear) {
                params.payload.startYear = Number(this.form.data.startYear)
              }
              if (this.form.data.endYear) {
                params.payload.endYear = Number(this.form.data.endYear)
              }
            }
          }
        }
      },
      authorized: {
        ajax: {
          get: {
            action: 'GET /enospray/formula',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = Object.assign({ typeCode: 'C', audited: true }, omit(this.form.data, 'idsIn', 'startYear', 'endYear'))
              params.payload.idsIn = this.form.data.idsIn ? [Number(this.form.data.idsIn)] : []
              if (this.form.data.startYear) {
                params.payload.startYear = Number(this.form.data.startYear)
              }
              if (this.form.data.endYear) {
                params.payload.endYear = Number(this.form.data.endYear)
              }
            }
          }
        }
      },
      unauthorized: {
        ajax: {
          get: {
            action: 'GET /enospray/formula',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = Object.assign({ typeCode: 'C', audited: false }, omit(this.form.data, 'idsIn', 'startYear', 'endYear'))
              params.payload.idsIn = this.form.data.idsIn ? [Number(this.form.data.idsIn)] : []
              if (this.form.data.startYear) {
                params.payload.startYear = Number(this.form.data.startYear)
              }
              if (this.form.data.endYear) {
                params.payload.endYear = Number(this.form.data.endYear)
              }
            }
          }
        }
      }
    }
  }
})
</script>
